import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ChatHistoryProvider } from './Components/ChatHistoryContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ChatHistoryProvider>
       <BrowserRouter>
          <App />
       </BrowserRouter>
  </ChatHistoryProvider>
);

document.addEventListener('DOMContentLoaded', function() {
  const newChatButton = document.querySelector('.side-menu-btn');
  const mobileSidebar = document.querySelector('.mobile-sidebar');

  if (newChatButton && mobileSidebar) {
    newChatButton.addEventListener('click', function() {
      mobileSidebar.classList.add('hidden');
    });
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
