import React from 'react'
import Header from './Header'
import Footer from './Footer'

const Layout = ({ children }) => {
    return (
        <>

            <section className='main-sec'>
            <Header />
            
            <main style={{minHeight: "100vh"}}>
                {children}
            </main>

            <Footer />
            </section>


        </>
    )
}

export default Layout