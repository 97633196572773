import React, { createContext, useState, useContext } from 'react';

const ChatHistoryContext = createContext();

export const useChatHistory = () => {
  return useContext(ChatHistoryContext);
};

export const ChatHistoryProvider = ({ children }) => {
  const [chatHistory, setChatHistory] = useState([]);

  const addChatToHistory = (chat) => {
    setChatHistory((prevHistory) => [...prevHistory, chat]);
  };

  const deleteChat = (index) => {
    setChatHistory((prevHistory) => prevHistory.filter((_, i) => i !== index));
  };

  return (
    <ChatHistoryContext.Provider value={{ chatHistory, addChatToHistory, deleteChat }}>
      {children}
    </ChatHistoryContext.Provider>
  );
};
