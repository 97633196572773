import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Loader from '../Components/Loader';
import siteLogo from '../Assets/Images/sit-logo.png';
import { useTheme } from '../Components/ThemeContext';

const CstatsChat = () => {
  const [question, setQuestion] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { isDarkMode, setIsDarkMode } = useTheme();
  const textareaRef = React.useRef(null);
  const chatLogRef = React.useRef(null);
  const [isInitialMessage, setIsInitialMessage] = useState(true);

  const scrollToTop = () => {
    if (chatLogRef.current) {
      chatLogRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    scrollToTop();
  }, [messages]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      setQuestion('');
      handleSendMessage();
    }
  };

  const handleSendMessage = async () => {
    if (!question.trim()) return;

    setIsInitialMessage(false);
    resetTextareaHeight();

    const userMessage = { sender: 'user', text: question };
    setMessages((prevMessages) => [userMessage, ...prevMessages]);
    setQuestion('');

    const botLoadingMessage = { sender: 'bot', text: '', isLoading: true };
    setMessages((prevMessages) => [botLoadingMessage, ...prevMessages]);

    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        'https://cstats.back-dev.6hat.fr/ask',
        { question: userMessage.text },
        {
          headers: {
            Authorization: 'Bearer vkI9jw5eMjug5LiS1rG9lYATmWhE6qVe428ejwwgJrh92jdbiBJp2T7rwK2CItWR',
            'Content-Type': 'application/json',
          },
        }
      );

      const botResponse =
        response.data && (response.data.answer || response.data.output)
          ? response.data.answer || response.data.output
          : "I'm not sure how to answer that.";

      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        updatedMessages[0] = { sender: 'bot', text: botResponse, isLoading: false };
        return updatedMessages;
      });
    } catch (err) {
      console.error('API call error:', err);
      setError('Error fetching data');
    }

    setLoading(false);
  };

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleTextareaResize = (e) => {
    const textarea = e.target;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const resetTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
    }
  };

  const startNewChat = () => {
    setMessages([]);
    setQuestion('');
    setIsInitialMessage(true);
    scrollToTop();
    localStorage.removeItem('chatMessages');
  };

  useEffect(() => {
    const savedMessages = localStorage.getItem('chatMessages');
    if (savedMessages) {
      setMessages(JSON.parse(savedMessages));
      setIsInitialMessage(false);
    }
  }, []);

  useEffect(() => {
    if (messages.length > 0) {
      localStorage.setItem('chatMessages', JSON.stringify(messages));
    }
  }, [messages]);

  return (
    <div className="chat-container">
      <section className="chat-box">
        {isInitialMessage ? (
          <div className="initial-chat-input">
            <div className="chat-nav">
              <Link to="/">
                <span className="fa fa-arrow-right-from-bracket"></span>
                <span className="ms-2">Logout</span>
              </Link>

              <div className="centeral-logo">
                <Link to="/">
                  <img src={siteLogo} alt="" />
                </Link>
              </div>

              <div onClick={startNewChat}>
                <span className="me-2">Clear</span>
                <span className="fa fa-pen-to-square"></span>
              </div>
            </div>

            <div className="main-search-box initial">
              <h3 className="typing-animation">How can I help you?</h3>
              <div className="main-text-box">
                <textarea
                  ref={textareaRef}
                  rows={1}
                  className="chat-input-textarea"
                  placeholder="Your Question"
                  onChange={(e) => {
                    setQuestion(e.target.value);
                    handleTextareaResize(e);
                  }}
                  value={question}
                  disabled={loading}
                  onKeyDown={handleKeyDown}
                />
                <span
                  className="fa-sharp fa-solid fa-paper-plane send-message"
                  onClick={handleSendMessage}
                ></span>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="chat-input-holder top">
              <div className="main-search-box">
                <div className="main-text-box">
                  <textarea
                    ref={textareaRef}
                    rows={1}
                    className="chat-input-textarea"
                    placeholder="Your Question"
                    onChange={(e) => {
                      setQuestion(e.target.value);
                      handleTextareaResize(e);
                    }}
                    value={question}
                    disabled={loading}
                    onKeyDown={handleKeyDown}
                  />
                  <span
                    className="fa-sharp fa-solid fa-paper-plane send-message"
                    onClick={handleSendMessage}
                  ></span>
                </div>
              </div>
            </div>

            <div className="chat-nav">
              <Link to="/">
                <span className="fa fa-arrow-right-from-bracket"></span>
                <span className="ms-2">Logout</span>
              </Link>

              <div className="centeral-logo">
                <Link to="/">
                  <img src={siteLogo} alt="" />
                </Link>
              </div>

              <div onClick={startNewChat}>
                <span className="me-2">Clear</span>
                <span className="fa fa-pen-to-square"></span>
              </div>
            </div>

            <div className="main-chat-container" ref={chatLogRef}>
              {messages.map((msg, index) => (
                <div key={index} className={`chat-message ${msg.sender === 'user' ? 'user-message' : 'bot-message'}`}>
                  <div className="message">
                    {msg.isLoading ? <Loader /> : msg.text}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </section>
    </div>
  );
};

export default CstatsChat;
